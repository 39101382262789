"use client"

import { Auth, connectAuthEmulator, getAuth } from "firebase/auth"
import {
  connectFunctionsEmulator,
  Functions,
  getFunctions,
} from "firebase/functions"
import { FirebaseApp, initializeApp } from "firebase/app"
import {
  connectFirestoreEmulator,
  Firestore,
  getFirestore,
} from "firebase/firestore"
import { firebaseConfig } from "./clientApp"
import { createContext, useContext, useMemo } from "react"

export type FirebaseContextValue = {
  app: FirebaseApp
  auth: Auth
  functions: Functions
  firestore: Firestore
}

export const FirebaseContext = createContext<FirebaseContextValue | undefined>(
  undefined,
)

export const FirebaseProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const app = useMemo(() => initializeApp(firebaseConfig()), [])

  // -- auth -- setup and connect to emulator if it's enabled
  const authEmulatorAddress = process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR
  const auth = useMemo(() => {
    const auth = getAuth(app)
    if (authEmulatorAddress) {
      connectAuthEmulator(auth, authEmulatorAddress)
    }
    return auth
  }, [app, authEmulatorAddress])

  // -- functions -- setup and connect to emulator if it's enabled
  const functionsEmulatorAddress =
    process.env.NEXT_PUBLIC_FIREBASE_FUNCTIONS_EMULATOR_HOST
  const functions = useMemo(() => {
    const functions = getFunctions(app)
    if (functionsEmulatorAddress) {
      const [host, port] = functionsEmulatorAddress.split(":")
      connectFunctionsEmulator(functions, host, parseInt(port))
    }
    return functions
  }, [app, functionsEmulatorAddress])

  // -- firestore -- setup and connect to emulator if it's enabled
  const firestoreEmulatorAddress =
    process.env.NEXT_PUBLIC_FIREBASE_FIRESTORE_EMULATOR
  const firestore = useMemo(() => {
    const firestore = getFirestore(app)
    if (firestoreEmulatorAddress) {
      const [hostname, port] = firestoreEmulatorAddress.split(":")
      connectFirestoreEmulator(firestore, hostname, parseInt(port))
    }
    return firestore
  }, [app, firestoreEmulatorAddress])

  const value = useMemo(
    () => ({ app, auth, functions, firestore }),
    [app, auth, functions, firestore],
  )

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  )
}

// Returns the initialized firebase services
// Handles using the emulators if env vars exist
export const useFirebase = (): FirebaseContextValue => {
  const context = useContext(FirebaseContext)
  if (!context) {
    throw new Error("useFirebase must be used within a FirebaseProvider")
  }
  return context
}
