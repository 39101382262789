"use client"

import { ReviewAgreement } from "@/models/reviewAgreement"
import {
  Functions,
  httpsCallable,
  HttpsCallableResult,
} from "firebase/functions"

export function firebaseConfig() {
  return {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  }
}

export type SubscriptionSubscribeResponse = {
  data: {
    success: boolean
    offeringID: string
    priceID: string
    paymentDetails: {
      amount: number
      currency: string
      paymentIntent?: {
        id: string
        secret: string
      }
      setupIntent?: {
        id: string
        secret: string
      }
      ephemeralKey: string
      publishableKey: string
      customer: string
    }
    paymentType: string
    subscriptionID?: string
    purchaseID?: string
  }
}

export type PurchaseResponse = {
  data: {
    success: boolean
    offeringID: string
    priceID: string
    paymentDetails: {
      amount: number
      currency: string
      paymentIntent?: {
        id: string
        secret: string
      }
      setupIntent?: {
        id: string
        secret: string
      }
      ephemeralKey: string
      publishableKey: string
      customer: string
    }
    paymentType: string
  }
}

export type CreateAgreementResponse = {
  data: {
    success: boolean
    agreementID: string
    offeringID: string
    priceID: string
  }
}

export async function subscriptionSubscribe(
  functions: Functions,
  params: {
    offeringID: string
    priceID: string
    agreementID?: string
    override?: string
  },
): Promise<SubscriptionSubscribeResponse> {
  const result: HttpsCallableResult = await httpsCallable(
    functions,
    "subscriptions_subscribe",
  ).call(null, params)
  return result as SubscriptionSubscribeResponse
}

export async function createAgreement(
  functions: Functions,
  params: {
    offeringID: string
    priceID: string
  },
): Promise<CreateAgreementResponse> {
  return (await httpsCallable(functions, "offering_createAgreement").call(
    null,
    params,
  )) as CreateAgreementResponse
}

export async function purchase(
  functions: Functions,
  params: {
    offeringID: string
    priceID: string
    override?: string
  },
): Promise<PurchaseResponse> {
  return (await httpsCallable(functions, "purchase").call(
    null,
    params,
  )) as PurchaseResponse
}

export async function users_whoami(functions: Functions): Promise<unknown> {
  return await httpsCallable(functions, "users_whoami").call(null)
}

export type ImpersonateResponseData = { data: { token: string } }

export async function admin_impersonate(
  functions: Functions,
  params: { userID: string },
): Promise<ImpersonateResponseData> {
  return (await httpsCallable(functions, "admin_impersonate").call(
    null,
    params,
  )) as ImpersonateResponseData
}

export async function payments_changeDefaultPaymentMethod(
  functions: Functions,
  params: any,
): Promise<HttpsCallableResult> {
  return await httpsCallable(
    functions,
    "payments_changeDefaultPaymentMethod",
  ).call(null, params)
}

export type NewieFunctionsResponse<T> = {
  data: T
}

export type GenerateSetupIntentResponse = {
  publishableKey: string
  ephemeralKey: string
  setupIntent: string
  customerID: string
}

export async function payments_generateSetupIntent(
  functions: Functions,
  params: { currency: string },
): Promise<NewieFunctionsResponse<GenerateSetupIntentResponse>> {
  return (await httpsCallable(functions, "payments_generateSetupIntent").call(
    null,
    params,
  )) as NewieFunctionsResponse<GenerateSetupIntentResponse>
}

export async function users_requestEmailChange(
  functions: Functions,
  params: any,
): Promise<HttpsCallableResult> {
  return await httpsCallable(functions, "users_requestEmailChange").call(
    null,
    params,
  )
}

export type users_previewEmailChangeParams = {
  token: string
}

export async function users_previewEmailChange(
  functions: Functions,
  params: users_previewEmailChangeParams,
): Promise<HttpsCallableResult> {
  return await httpsCallable(functions, "users_previewEmailChange").call(
    null,
    params,
  )
}

export type users_confirmEmailChangeParams = {
  token: string
}

export async function users_confirmEmailChange(
  functions: Functions,
  params: users_confirmEmailChangeParams,
): Promise<HttpsCallableResult> {
  return await httpsCallable(functions, "users_confirmEmailChange").call(
    null,
    params,
  )
}

export type users_rejectEmailChangeParams = {
  token: string
}

export async function users_rejectEmailChange(
  functions: Functions,
  params: users_rejectEmailChangeParams,
): Promise<HttpsCallableResult> {
  return await httpsCallable(functions, "users_rejectEmailChange").call(
    null,
    params,
  )
}

export type payments_retryParams = {
  stripeInvoiceID: string
}

export type PaymentsRetryResponse = {
  data: {
    charged: boolean
    failureReason?: string
  }
  success?: boolean
  message?: string
}

export async function payments_retry(
  functions: Functions,
  params: payments_retryParams,
): Promise<PaymentsRetryResponse> {
  return (await httpsCallable(functions, "payments_retry").call(
    null,
    params,
  )) as PaymentsRetryResponse
}

export type ListPaymentMethodsResponsePaymentMethod = {
  id: string
  default: boolean
  type: string
  display: {
    icon: string
    title: string
    subtitle: string
  }
  card?: {
    brand: string
    last4: string
    wallet?: {
      type: string
    }
  } | null
  au_becs_debit?: {
    bsb_number: string
    last4: string
    fingerprint: string
  } | null
}

export interface PaymentMethodForCurrency {
  currency: string
  stripeCustomerID: string
  paymentMethods: ListPaymentMethodsResponsePaymentMethod[]
}

export type ListPaymentMethodsResponse = {
  data: {
    currencies: [PaymentMethodForCurrency]
  }
}

export async function payments_listPaymentMethods(
  functions: Functions,
): Promise<ListPaymentMethodsResponse> {
  return (await httpsCallable(functions, "payments_listPaymentMethods").call(
    null,
  )) as ListPaymentMethodsResponse
}

export type AuthRequestLoginEmailResponse = {
  data: {
    nonce: string
    success: boolean
    message?: string
    code?: string
  }
}

export type AuthRequestLoginEmailParams = {
  email: string
}

export async function AuthRequestLoginEmail(
  functions: Functions,
  params: AuthRequestLoginEmailParams,
): Promise<AuthRequestLoginEmailResponse> {
  return (await httpsCallable(functions, "auth_requestLoginEmail").call(
    null,
    params,
  )) as AuthRequestLoginEmailResponse
}

export type AuthLoginWithEmailResponse = {
  data: {
    email: string
    token: string
    success: boolean
    message?: string
    code?: string
  }
}

export type AuthLoginWithEmailParams = {
  email: string
  nonce: string
  code: string
}

export async function AuthLoginWithEmail(
  functions: Functions,
  params: AuthLoginWithEmailParams,
): Promise<AuthLoginWithEmailResponse> {
  return (await httpsCallable(functions, "auth_loginWithEmail").call(
    null,
    params,
  )) as AuthLoginWithEmailResponse
}

export type UsersSyncProfileResponse = {
  data: {
    success: boolean
    message?: string
  }
}

export async function UsersSyncProfile(
  functions: Functions,
): Promise<UsersSyncProfileResponse> {
  const callable = httpsCallable(functions, "users_syncProfile")
  const response = (await callable.call(null)) as UsersSyncProfileResponse

  if (response.data.success) {
    return response
  } else {
    throw new Error("Unsuccessful response calling UserSyncProfile", {
      cause: {
        message: "Unknown Error",
        ...response.data,
      },
    })
  }
}

export async function agreements_review(
  functions: Functions,
  params: {
    agreementID: string
  },
): Promise<ReviewAgreement> {
  const response = (
    await httpsCallable(functions, "agreements_review").call(null, params)
  ).data as ReviewAgreement
  if (response.success) {
    return response
  } else {
    throw new Error("Unsuccessful response calling agreements_review", {
      cause: {
        message: "Unknown Error",
        ...response,
      },
    })
  }
}

export type PaymentsFetchReceiptURLResponse =
  | {
      receiptURL: string
      success: true
    }
  | {
      success: false
      code: string
      message: string
    }

export async function payments_fetchReceiptURL(
  functions: Functions,
  params: {
    stripeInvoiceID: string
  },
): Promise<PaymentsFetchReceiptURLResponse> {
  return (
    await httpsCallable(functions, "payments_fetchReceiptURL").call(
      null,
      params,
    )
  ).data as PaymentsFetchReceiptURLResponse
}

export type PaymentsSendReceiptEmailResponse =
  | {
      notified: boolean
      success: true
    }
  | {
      success: false
      code: string
      message: string
    }

export async function payments_sendReceiptEmail(
  functions: Functions,
  params: {
    stripeInvoiceID: string
  },
): Promise<PaymentsSendReceiptEmailResponse> {
  return (
    await httpsCallable(functions, "payments_sendReceiptEmail").call(
      null,
      params,
    )
  ).data as PaymentsSendReceiptEmailResponse
}
